import * as React from "react";
import BackgroundImage from "gatsby-background-image";
import { useFormik } from "formik";
import * as Yup from "yup";

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

interface IProps {
  data: {
    nodes: Array<{
      uid: string;
      data: {
        title: {
          text: string;
        };
        description: {
          text: string;
          html: string;
        };
        backgroundimage: any;
        buttontitle: {
          text: string;
        };
        successmessage: {
          text: string;
        };
        errormessage: {
          text: string;
        };
        body: Array<{
          items: Array<{
            label: {
              text: string;
            };
            field_name: {
              text: string;
            };
            type: string;
            placeholder: {
              text: string;
            };
          }>;
        }>;
      };
    }>;
  };
}

const ContactUs = (props: IProps) => {
  const formData = props.data.nodes[0];

  const initialValues: any = {};
  formData.data.body[0].items.forEach(field => {
    initialValues[field.field_name.text] = "";
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("* Required"),
      email: Yup.string()
        .email("Invalid email addresss`")
        .required("* Required"),
      message: Yup.string().required("* Required"),
    }),
    onSubmit: values => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...values }),
      })
        .then(() => alert(formData.data.successmessage.text))
        .catch(() => alert(formData.data.errormessage.text));
    },
  });

  return (
    <BackgroundImage
      id={formData.uid}
      className="contact-2-area pt-120 pb-130"
      backgroundColor="rgba(0,0,0,.3)"
      fluid={formData.data.backgroundimage.localFile.childImageSharp.fluid}
      style={{ backgroundPosition: "0 0", backgroundPositionY: 0 }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,.3)",
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 offset-lg-2 offset-xl-3">
            <div className="section-title text-center section-title-white ml-50 mr-50 mb-75">
              <span className="border-left-1"></span>
              <span>{formData.data.title.text}</span>
              <span className="border-right-1"></span>
              <h1>{formData.data.description.text}</h1>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 offset-md-3 offset-lg-4">
          <div className="appointment-wrapper">
            <form
              className="appointment-form needs-validation was-validated"
              method="POST"
              action="/"
              name={formData.uid}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                {formData.data.body[0].items.map(field => {
                  const fieldname = field.field_name.text;
                  return (
                    <div key={`field-${fieldname}`} className="col-12">
                      <div className="form-box mb-30">
                        {(field.type === "text" || field.type === "email") && (
                          <input
                            type={field.type}
                            id={fieldname}
                            name={fieldname}
                            placeholder={field.placeholder.text}
                            onChange={formik.handleChange}
                            value={formik.values[fieldname]}
                          />
                        )}
                        {field.type === "textarea" && (
                          <textarea
                            cols={30}
                            rows={10}
                            id={fieldname}
                            name={fieldname}
                            placeholder={field.placeholder.text}
                            onChange={formik.handleChange}
                            value={formik.values[fieldname]}
                          />
                        )}
                        {formik.touched[fieldname] &&
                          formik.errors[fieldname] && (
                            <div className="invalid-feedback">
                              {formik.errors[fieldname]}
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
                <div className="col-12">
                  <div className="contact-btn contact-2-btn text-center">
                    <button className="btn" type="submit">
                      <span className="btn-text">
                        {formData.data.buttontitle.text}{" "}
                        <i className="far fa-long-arrow-right"></i>
                      </span>
                      <span className="btn-border"></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default ContactUs;
